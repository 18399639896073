import React from "react";
import { Contact } from "../../components";

const ContactPage = () => {
  return (
    <>
      <Contact />
    </>
  );
};

export default ContactPage;
